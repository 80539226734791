
import router from "@/router";
import store from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const keydownListener = async (e: any) => {
      if (e.key === "Escape") {
        router.push({ name: "Typing" });
      }
    };

    const setKeyboardEventListener = () => {
      window.addEventListener("keydown", keydownListener);
    };

    setKeyboardEventListener();

    return { store };
  },
});
